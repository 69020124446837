<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入工单号" suffix-icon="el-icon-search" v-model="reconum"></el-input>
      <el-select clearable multiple v-model="type" placeholder="请选择类型">
        <el-option label="车间报修" value="车间报修"></el-option>
        <el-option label="维修自查" value="维修自查"></el-option>
        <el-option label="获批技改" value="获批技改"></el-option>
        <el-option label="管理巡审" value="管理巡审"></el-option>
        <el-option label="定期维修" value="定期维修"></el-option>
      </el-select>
      <el-select clearable multiple v-model="state" placeholder="请选择状态">
        <el-option label="工单关闭" value="工单关闭"></el-option>
        <el-option label="作业结束" value="作业结束"></el-option>
        <el-option label="已接单" value="已接单"></el-option>
        <el-option label="维修中" value="维修中"></el-option>
        <el-option label="暂停中" value="暂停中"></el-option>
      </el-select>
      <el-select v-model="lineId" clearable multiple placeholder="请选择车间">
        <el-option v-for="line in lines" :key="line.id" :label="line.name" :value="line.id"></el-option>
      </el-select>
      <el-input style="width: 200px" placeholder="请输入设备名" suffix-icon="el-icon-search" v-model="equipmentName"></el-input>
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-checkbox v-model="important">我负责的车间</el-checkbox>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <el-upload action="http://39.106.92.106:9090/failureRecord/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5" v-if="user.role!=='ROLE_READ'" >导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>

      <el-checkbox v-model="goodTag" style="margin-left: 985px">优质工单</el-checkbox>
      <el-checkbox v-model="badTag">需改善工单</el-checkbox>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50"></el-table-column>
<!--      <el-table-column prop="id" label="ID" width="50" sortable></el-table-column>-->
      <el-table-column prop="id" label="ID" width="60" sortable>
        <!-- 自定义ID列的单元格样式 -->
        <template slot-scope="scope">
          <div :style="getRowStyle(scope.row)">
            {{ scope.row.id }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="reportId" label="报修单ID" width="60" > </el-table-column>
      <el-table-column label="工单号" width="80">
        <template v-slot="scope">
          <span>{{ getTag(scope.row.reconum)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" width="80"></el-table-column>
<!--      <el-table-column prop="state" label="状态" width="100"></el-table-column>-->
      <el-table-column prop="state" label="状态" width="70">
        <template slot-scope="scope">
          <div :style="getRowStyle(scope.row)">
            {{ scope.row.state }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="接单时间" width="95"></el-table-column>
<!--      <el-table-column prop="stopTime" label="暂停时间" width="95"></el-table-column>-->
      <el-table-column prop="endTime" label="结束时间" width="95"></el-table-column>
      <el-table-column label="车间" width="95">
        <template v-slot="scope">
          <span>{{ lines.find(v => v.id === scope.row.lineId) ? lines.find(v => v.id === scope.row.lineId).name : ''  }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="设备" width="95">-->
<!--        <template v-slot="scope">-->
<!--          <span>{{ allequipment.find(v => v.id === scope.row.equipmentId) ? allequipment.find(v => v.id === scope.row.equipmentId).name : '' }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="equipmentName" label="设备" width="80"></el-table-column>
      <el-table-column label="总工时(h)" width="95">
        <template v-slot="scope">
          <span>{{ formatTime(scope.row.workHour)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="接单人ID" v-if="false"></el-table-column>
      <el-table-column label="维修工(工时）" width="110">
        <template v-slot="scope">
          <span>{{ getMaintainerNames(scope.row.reconum)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="故障描述"></el-table-column>
      <el-table-column prop="shuoming" label="维修说明"></el-table-column>
      <el-table-column prop="analysis" label="维修分析">
        <template slot-scope="scope">
          <span>{{ scope.row.analysis !== null && scope.row.analysis !== undefined ? scope.row.analysis : 'N/A' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="engineer" label="审核工程师"></el-table-column>
      <el-table-column prop="signTime" label="审批时间" width="95"></el-table-column>
      <el-table-column label="审核" width="320">
        <template v-slot="scope">
          <el-button type="primary" plain @click="gotoDetail(scope.row.id)" size="small">查看详情</el-button>
          <el-button v-if="haveComponent(scope.row.id)" type="primary" plain @click="viewComponent(scope.row.id)">查询领用备件</el-button>
          <el-button type="success" @click="agree(scope.row)" v-if="user.role!=='ROLE_READ'" >审批通过</el-button>
          <el-button type="danger" @click="delAll(scope.row.reportId,scope.row.id)"  v-if="user.role!=='ROLE_READ'" >删除 <i class="el-icon-remove-outline"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="ID" v-if="false" >
          <el-input v-model="form.id" disabled visible="false" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工单号" v-if="false" >
          <el-input v-model="form.reconum" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select clearable v-model="form.type" placeholder="请选择类别" style="width: 100%">
            <el-option label="车间报修" value="车间报修"></el-option>
            <el-option label="维修自查" value="维修自查"></el-option>
            <el-option label="管理巡审" value="管理巡审"></el-option>
            <el-option label="获批技改" value="获批技改"></el-option>
            <el-option label="定期维修" value="定期维修"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable v-model="form.state" placeholder="请选择状态" style="width: 100%">
            <el-option label="管理员锁定" value="管理员锁定"></el-option>
            <el-option label="维修中" value="维修中"></el-option>
            <el-option label="暂停中" value="暂停中"></el-option>
            <el-option label="作业结束" value="作业结束"></el-option>
            <el-option label="工单关闭" value="工单关闭"></el-option>
            <el-option label="审批不通过" value="审批不通过"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="form.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="暂停时间">
          <el-date-picker v-model="form.stopTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="form.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="工时(h)">
          <el-input v-model="form.workHour" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修说明">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入维修说明"
              v-model="form.shuoming">
          </el-input>
        </el-form-item>
        <el-form-item label="维修分析">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入维修分析"
              v-model="form.analysis">
          </el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入备注"
              v-model="form.notes">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="dialogFormVisible2" width="80%" :close-on-click-modal="false">
      <div class="dialog-content" style="display: flex; gap: 20px;">
        <!-- 左边部分 -->
        <el-card class="box-card" style="flex: 1; font-size: 30px;">
          <div slot="header" class="clearfix">
            <span>报修单</span>
          </div>
          <el-form label-width="100px" size="large">
            <el-form-item label="报修人:">
              <span>{{ users.find(v => v.id === reports.userId) ? users.find(v => v.id === reports.userId).nickname : '' }}</span>
            </el-form-item>
            <el-form-item label="车间">
              <span>{{getLineName2(reports.lineId)}}</span>
            </el-form-item>
            <el-form-item label="设备:">
              <span>{{ reports.equipmentName }}</span>
            </el-form-item>
            <el-form-item label="工单类型:">
              <span>{{ reports.type }}</span>
            </el-form-item>
            <el-form-item label="故障类型:">
              <span>{{ reports.failureType }}</span>
            </el-form-item>
            <el-form-item label="报修时间:">
              <span>{{ reports.recordTime }}</span>
            </el-form-item>
            <el-form-item label="故障时间:">
              <span>{{ reports.failureTime }}</span>
            </el-form-item>
            <el-form-item label="故障描述:">
              <span>{{ reports.description }}</span>
            </el-form-item>
            <el-form-item label="故障图">
              <div style="display: flex; flex-wrap: wrap; gap: 10px;">
                <el-image
                    v-for="(file, index) in reportFiles"
                    :key="index"
                    :src="file.fileUrl"
                    :preview-src-list="reportFiles.map(item => item.fileUrl)"
                    style="width: 100px; height: 100px; margin-bottom: 10px;"
                >
                </el-image>
              </div>
              <el-button type="primary" plain  @click="goChangePhoto(reports.id)" size="small">重传故障图 <i class="el-icon-edit"></i></el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <!-- 右边部分 -->
        <el-card class="box-card" style="flex: 1; font-size: 30px;">
          <div slot="header" class="clearfix">
            <span>工单</span>
            <el-button type="success" v-if="this.user.role!=='ROLE_READ'" @click="handleEdit(records)" size="large" style="margin-left: 50px;">编辑 <i class="el-icon-edit"></i></el-button>
            <el-button type="primary" plain  v-if="this.user.role!=='ROLE_READ'" @click="handleGroup(records.reconum)" size="large">编辑维修工 <i class="el-icon-document"></i></el-button>
            <el-button type="danger"  v-if="this.user.role!=='ROLE_READ'" @click="delAll(records.reportId,records.id)" size="large">删除 <i class="el-icon-remove-outline"></i></el-button>

            <el-button round v-if="this.user.role!=='ROLE_READ'" @click="setTag(records.id,'good')" style="margin-left: 15px;" size="large">点赞👍</el-button>
            <el-button round v-if="this.user.role!=='ROLE_READ'" @click="setTag(records.id,'bad')" size="large">需改善😟</el-button>
          </div>
          <el-form label-width="100px" size="large">
            <el-form-item label="ID">
              <span>{{ records.id }}</span>
            </el-form-item>
            <el-form-item label="工单号">
              <span>{{ records.reconum }}</span>
            </el-form-item>
            <el-form-item label="类型">
              <span>{{ records.type }}</span>
            </el-form-item>
            <el-form-item label="状态">
              <span>{{ records.state }}</span>
            </el-form-item>
            <el-form-item label="接单人">
              <span>{{ users.find(v => v.id === records.userId) ? users.find(v => v.id === records.userId).nickname : '' }}</span>
            </el-form-item>
            <el-form-item label="维修工">
              <template v-slot="scope">
                <span>{{ getMaintainerNames(records.reconum)  }}</span>
              </template>
            </el-form-item>
            <el-form-item label="接单时间">
              <span>{{ records.startTime }}</span>
            </el-form-item>
            <el-form-item label="暂停时间" v-if="records.stopTime">
              <span>{{ records.stopTime }}</span>
            </el-form-item>
            <el-form-item label="暂停原因" v-if="records.pauseDescription">
              <span>{{ records.pauseDescription }}</span>
            </el-form-item>
            <el-form-item label="结束时间">
              <span>{{ records.endTime }}</span>
            </el-form-item>
            <el-form-item label="工时(h)">
              <template v-slot="scope">
                <span>{{ formatTime(records.workHour)  }}</span>
              </template>
            </el-form-item>
            <el-form-item label="维修说明">
              <span>{{ records.shuoming }}</span>
            </el-form-item>
            <el-form-item label="维修分析" v-if="records.analysis">
              <span>{{ records.analysis }}</span>
            </el-form-item>
            <el-form-item label="备注" v-if="records.notes">
              <span>{{ records.notes }}</span>
            </el-form-item>
            <el-form-item label="评分" v-if="comments.value">
              <el-rate
                  style="margin-top: 8px;"
                  v-model="comments.value"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}">
              </el-rate>
            </el-form-item>
            <el-form-item label="评价" v-if="comments.comment">
              <span>{{comments.comment}}</span>
            </el-form-item>
            <el-form-item label="到达图" v-if="records.avatarUrl">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="records.avatarUrl"
                  :preview-src-list="[records.avatarUrl]">
              </el-image>
              <el-button type="primary" plain  @click="goChangePhoto3(records.id)" size="small">重传到达图 <i class="el-icon-edit"></i></el-button>
            </el-form-item>
            <el-form-item label="完成图">
              <div style="display: flex; flex-wrap: wrap; gap: 10px;">
                <el-image
                    v-for="(file, index) in recordFiles"
                    :key="index"
                    :src="file.fileUrl"
                    :preview-src-list="recordFiles.map(item => item.fileUrl)"
                    style="width: 100px; height: 100px; margin-bottom: 10px;"
                >
                </el-image>
              </div>
              <el-button type="primary" plain  @click="goChangePhoto2(records.id)" size="small">重传完成图 <i class="el-icon-edit"></i></el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </el-dialog>

    <el-dialog title="备件清单" :visible.sync="dialogFormVisible3" width="60%" :close-on-click-modal="false">
      <el-table :data="recordComponents" border stripe :header-cell-class-name="'headerBg'">
        <el-table-column prop="recordId" label="工单ID" width="80"></el-table-column>
        <el-table-column label="工单号" width="120">
          <template v-slot="scope">
            <span>{{ getReconum(scope.row.recordId) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="compnum" label="备件号"></el-table-column>
        <el-table-column label="备件名">
          <template v-slot="scope">
            <span>{{ getCompName(scope.row.compnum) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sum" label="数量"></el-table-column>
        <el-table-column prop="price" label="总价"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="审批不通过" :visible.sync="dialogFormVisible4" width="30%" :close-on-click-modal="false">
      <el-form label-width="120px" size="small" style="width: 90%">
        <el-form-item label="ID" v-if="false" >
          <el-input v-model="form.id" disabled visible="false" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="不通过原因">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入不通过原因"
              v-model="form.reason">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="save2">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑维修工" :visible.sync="dialogFormVisible5" width="35%" :close-on-click-modal="false">
<!--      <el-form label-width="100px" size="small" style="width: 90%">-->
<!--        <el-form-item label="工单号">-->
<!--          <el-input v-model="form2.reconum" disabled autocomplete="off"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="维修工">-->
<!--          <el-select v-model="form2.selectedUsers" multiple filterable placeholder="请选择">-->
<!--            <el-option-->
<!--                v-for="item in users"-->
<!--                :key="item.id"-->
<!--                :label="item.nickname"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="dialogFormVisible5 = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="handleGroupUser">确 定</el-button>-->
<!--      </div>-->
      <el-button type="primary" @click="handleAddMaintainer(recomaintainers[0].reconum)">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-table :data="recomaintainers" border stripe :header-cell-class-name="'headerBg'">
        <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
        <el-table-column prop="reconum" label="工单号" width="120"></el-table-column>
        <el-table-column prop="userName" label="维修工" width="80"></el-table-column>
        <el-table-column prop="workHour" label="工时" width="80"></el-table-column>
        <el-table-column label="操作"  align="center">
          <template slot-scope="scope">
            <el-button type="success" @click="handleEditMaintainer(scope.row)">编辑工时 <i class="el-icon-edit"></i></el-button>
            <el-popconfirm
                class="ml-5"
                confirm-button-text='确定'
                cancel-button-text='我再想想'
                icon="el-icon-info"
                icon-color="red"
                title="您确定删除吗？"
                @confirm="delMaintainer(scope.row.id,scope.row.reconum)"
            >
              <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="信息" :visible.sync="dialogFormVisible6" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="工单号" >
          <el-input v-model="form3.reconum" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修工">
          <el-select clearable v-model="form3.userId" placeholder="请选择维修工" style="width: 100%">
            <el-option v-for="item in users" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工时(h)">
          <el-input v-model="form3.workHour" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible6 = false">取 消</el-button>
        <el-button type="primary" @click="save4">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="信息" :visible.sync="dialogFormVisible7" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="ID" v-if="false" >
          <el-input v-model="form3.id" disabled visible="false" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工单号" v-if="false" >
          <el-input v-model="form3.reconum" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修工ID" v-if="false" >
          <el-input v-model="form3.userId" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修工">
          <el-input v-model="form3.userName" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工时(h)">
          <el-input v-model="form3.workHour" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible7 = false">取 消</el-button>
        <el-button type="primary" @click="save4">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="重传故障图" :visible.sync="dialogFormVisible8" width="30%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="报修单ID">
          <el-input v-model="form4.id" disabled autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="故障图">
          <!--              <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"-->
          <!--                       :on-success="handleAvatarSuccess" style="display: inline-block" :multiple="true">-->
          <!--              <el-button type="flex" justify="center">上传照片</el-button><span style="font-size: 12px">（可传多张）</span>-->
          <!--              </el-upload>-->
          <el-upload
              class="avatar-uploader"
              action="http://39.106.92.106:9090/files/upload"
              :show-file-list="true"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeUpload"
              :multiple="true"
          >
            <el-button type="primary">上传照片</el-button>
            <span style="font-size: 12px">（可传多张）</span>
          </el-upload>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible8 = false">取 消</el-button>
        <el-button type="primary" @click="changePhoto">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="重传完成图" :visible.sync="dialogFormVisible9" width="30%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="工单ID">
          <el-input v-model="form5.id" disabled autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="完成图">
          <el-upload
              class="avatar-uploader"
              action="http://39.106.92.106:9090/files/upload"
              :show-file-list="true"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeUpload"
              :multiple="true"
          >
            <el-button type="primary">上传照片</el-button>
            <span style="font-size: 12px">（可传多张）</span>
          </el-upload>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible9 = false">取 消</el-button>
        <el-button type="primary" @click="changePhoto2">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="重传到达图" :visible.sync="dialogFormVisible10" width="30%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="工单ID">
          <el-input v-model="form6.id" disabled autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="到达图片">
          <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"
                     :on-success="handleAvatarSuccess3" style="display: inline-block" :multiple="false">
            <el-button type="flex" justify="center">上传照片</el-button><span style="font-size: 12px">（一张）</span>
          </el-upload>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible10 = false">取 消</el-button>
        <el-button type="primary" @click="changePhoto3">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "RecordSign",
  data() {
    return {
      tableData: [],
      users: [],
      recomaintain: [],
      recomaintainers: [],
      recordComponents: [],
      haveComponents: [],
      recordFiles: [],
      reportFiles: [],
      components: [],
      comments: [],
      lines: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      total1: 0,
      pageNum1: 1,
      pageSize1: 10,
      reconum: "",
      startDate : "",
      endDate : "",
      type: [],
      lineId: [],
      important: false,
      goodTag: false,
      badTag: false,
      recordTag: "",
      state: [],
      equipmentName: "",
      form: {},
      form2: {},
      form3: {},
      records: [],
      reports: [],
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      dialogFormVisible6: false,
      dialogFormVisible7: false,
      dialogFormVisible8: false,
      dialogFormVisible9: false,
      dialogFormVisible10: false,
      form4: [],
      form5: [],
      form6: [],
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      baseURLdata: request.defaults.baseURL,
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      if(this.goodTag && !this.badTag){
        this.recordTag = "good"
      }
      if(this.badTag && !this.goodTag){
        this.recordTag = "bad"
      }
      if(this.goodTag && this.badTag){
        this.recordTag = "tagged"
      }
      if(!this.goodTag && !this.badTag){
        this.recordTag = ""
      }
      if(this.important === false){
        this.request.get("/failureRecord/page", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            reconum: this.reconum,
            state: this.state.length > 0 ? this.state.join(',') : '',
            type: this.type.length > 0 ? this.type.join(',') : '',
            lineId: this.lineId.length > 0 ? this.lineId.join(',') : '',
            equipmentName: this.equipmentName,
            startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
            endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
            recordTag: this.recordTag
          }
        }).then(res => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }else{
        this.request.get("/failureRecord/pageFilter", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            reconum: this.reconum,
            state: this.state,
            type: this.type,
            lineId: this.lineId,
            equipmentName: this.equipmentName,
            startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
            endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : '',
            userId: this.user.id
          }
        }).then(res => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }

      this.request.get("/user").then(res => {
        this.users = res.data;
      });
      this.request.get("/recordMaintainer").then(res => {
        this.recomaintain = res.data;
      });
      this.request.get("/component").then(res => {
        this.components = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data
      });
      this.request.get("/recordComponent").then(res => {
        this.haveComponents = res.data;
      });
      this.request.get("/repairReport").then(res => {
        this.reports = res.data;
      });
    },
    loadOneRecordMaintainer(reconum){
      this.request.get("/recordMaintainer/findByReconum/"+reconum).then(res => {
        this.recomaintainers = res.data;
      });
    },
    save() {
      this.request.post("/failureRecord", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    save4() {
      this.request.post("/recordMaintainer", this.form3).then(res => {
        if (res.code === '200') {
          this.request.post("/failureRecord/updateWorkHour2/"+this.form3.reconum).then(res => {
            this.$message.success("保存成功")
            this.dialogFormVisible6 = false
            this.dialogFormVisible7 = false
            this.load()
            this.loadOneRecordMaintainer(this.form3.reconum)
          })
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAddMaintainer(reconum) {
      this.dialogFormVisible6 = true
      this.form3 = {
        reconum: reconum,
      }
    },
    handleEditMaintainer(row){
      this.form3 = {
        id: row.id,
        reconum: row.reconum,
        userId: row.userId,
        userName: row.userName,
        workHour: row.workHour,
      }
      this.dialogFormVisible7 = true
    },
    delMaintainer(id,reconum) {
      this.request.delete("/recordMaintainer/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
          this.loadOneRecordMaintainer(reconum)
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    gotoDetail(recordId){
      this.request.get("/failureRecord/"+recordId).then(res => {
        this.records = res.data;
        this.request.get("/repairReport/"+this.records.reportId).then(res => {
          this.reports = res.data;
        });
        this.request.get("/reportFile/getByReportId/"+this.records.reportId).then(res => {
          this.reportFiles = res.data
        })
        this.request.get("/comment/findRecord/"+this.records.id).then(res => {
          this.comments = res.data;
        });
        this.request.get("/recordFile/getByRecordId/"+this.records.id).then(res => {
          this.recordFiles = res.data
        })
      });
      this.dialogFormVisible2 = true
    },
    haveComponent(id) {
      // 确保`recordComponents`数据已经加载
      if (!this.haveComponents || this.haveComponents.length === 0) {
        return false;
      }

      // 查找是否有对应ID的备件信息
      return this.haveComponents.some(component => component.recordId === id);
    },
    viewComponent(recordId) {
      this.request.get("/recordComponent/findByRecordId/"+recordId).then(res => {
          this.recordComponents = res.data;
      }).catch(error => {
        console.error("请求失败", error); // 处理请求失败的情况
      });
      this.dialogFormVisible3 = true;
    },
    getMaintainerNames(reconum) {
      const recomt = this.recomaintain.filter(item => item.reconum === reconum);
      return recomt.map(item => {
        const user = this.users.find(u => u.id === item.userId);

        // Check if workHour is a valid number and not null or undefined
        const workHour = item.workHour != null ? item.workHour.toFixed(2) : '0.00';
        const minutes = Math.floor(workHour * 60);

        const allData = user ? `${user.nickname} (${minutes}分钟)` : '';
        return allData;
      }).join(', ');
    },
    getMaintainerIds(reconum) {
      const recomt = this.recomaintain.filter(item => item.reconum === reconum);
      console.log(recomt.map(item => item.userId))
      return recomt.map(item => item.userId);
    },
    agree(row){
      if(this.user.role!=='ROLE_READ'){
        if(row.state ==='工单关闭'){
          this.$message.error("该工单已工单关闭，请勿重复操作")
        }else {
          //this.form = JSON.parse(JSON.stringify(row))
          //this.form.state = '工单关闭';
          //this.form.engineer = this.users.find(u => u.id === this.user.id).nickname
          const engineer = this.users.find(u => u.id === this.user.id).nickname
          //console.log(this.form.engineer)

          //this.save();
          this.request.post("/failureRecord/sign/"+row.reconum+"/"+engineer).then(res => {
            if (res.code === '200') {
              this.$message.success("审批成功")
              this.request.post("/user/updateWorkHour1", row.reconum)
              this.load()
            } else {
              this.$message.error("审批失败")
            }
          })
        }
      }else{
        this.$message.warning("该账号没有权限")
      }

    },
    reject(row){
        this.dialogFormVisible4 = true;
        this.form = JSON.parse(JSON.stringify(row))
    },
    save2(){
      const formData = {
        recordId: this.form.id,
        reason: this.form.reason,
      }
      this.request.post("/failureRecord/reject/"+this.form.id).then(res => {
        if (res.code === '200') {
          this.$message.success("审批成功")
          this.request.post("/wechat/sendReject",formData)
          this.dialogFormVisible4 = false
          this.load()
        } else {
          this.$message.error("审批失败")
        }
    })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if(this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleEdit(records) {
      this.form = records
      this.dialogFormVisible = true
    },
    handleGroup(reconum) {
      // this.form2 = {
      //   selectedUsers: this.getMaintainerIds(reconum) || [],// 存储选中的用户ID
      //   reconum: reconum,
      // }
      this.loadOneRecordMaintainer(reconum)
      this.dialogFormVisible5 = true;
    },
    handleGroupUser() {
      this.request.post("/recordMaintainer/handRecoMt", this.form2).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible5 = false
          this.load()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    del(id) {
      this.request.delete("/failureRecord/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    setTag(recordId, recordTag){
      this.request.post("/failureRecord/setTag/"+recordId+"/"+recordTag).then(res => {
        if (res.code === '200') {
          this.$message.success("标记成功")
          this.load()
          this.dialogFormVisible2 = false;
        } else {
          this.$message.error("标记失败")
        }
      })
    },
    delAll(reportId,recordId){
      const formData = {
        userId: this.user.id,
        userName : this.users.find(u => u.id === this.user.id).nickname,
        operate: "删除工单",
        information: "删除报修单ID:"+reportId+",工单ID:"+recordId
      }
      this.request.delete("/repairReport/" + reportId).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
      this.request.delete("/failureRecord/" + recordId).then(res => {
        if (res.code === '200') {
          this.request.post("/operateLog",formData)
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
      this.dialogFormVisible2 = false
    },
    goChangePhoto(reportId){
      this.form4.id = reportId
      this.dialogFormVisible8 = true
    },
    changePhoto(){
      const reportFileDTO = {
        id: this.form4.id,
        fileUrls: this.form4.fileUrls
      };
      this.request.post("/reportFile/changeFile", reportFileDTO).then(res => {
        if (res.code === '200') {
          this.$message.success("提交成功")
          this.dialogFormVisible2 = false
          this.dialogFormVisible8 = false
        } else {
          this.$message.error("提交失败")
        }
      })
    },
    goChangePhoto2(recordId){
      this.form5.id = recordId
      this.dialogFormVisible9 = true
    },
    changePhoto2(){
      const reportFileDTO = {
        id: this.form5.id,
        fileUrls: this.form5.fileUrls
      };
      this.request.post("/recordFile/changeFile", reportFileDTO).then(res => {
        if (res.code === '200') {
          this.$message.success("提交成功")
          this.dialogFormVisible2 = false
          this.dialogFormVisible9 = false
        } else {
          this.$message.error("提交失败")
        }
      })
    },
    goChangePhoto3(recordId){
      this.form6.id = recordId
      this.dialogFormVisible10 = true
    },
    changePhoto3(){
      const RecordPhoto = {
        id: this.form6.id,
        avatarUrl: this.form6.avatarUrl
      };
      this.request.post("/failureRecord/changeFile", RecordPhoto).then(res => {
        if (res.code === '200') {
          this.$message.success("提交成功")
          this.dialogFormVisible2 = false
          this.dialogFormVisible10 = false
        } else {
          this.$message.error("提交失败")
        }
      })
    },
    handleAvatarSuccess(res) {
      if (!Array.isArray(this.form4.fileUrls)) {
        this.form4.fileUrls = [];
      }
      this.form4.fileUrls.push(res.data);
    },
    handleAvatarSuccess2(res) {
      if (!Array.isArray(this.form5.fileUrls)) {
        this.form5.fileUrls = [];
      }
      this.form5.fileUrls.push(res.data);
    },
    handleAvatarSuccess3(res) {
      this.form6.avatarUrl = res.data
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const width = img.width;
            const height = img.height;

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            // 开始以 0.9 的质量压缩图片，然后递归调整
            this.compressImage(canvas, file.type, 0.5, resolve, reject);
          };
        };

        reader.onerror = (error) => {
          console.error("图片读取失败：", error);
          reject(error);
        };
      });
    },

    compressImage(canvas, fileType, quality, resolve, reject) {
      canvas.toBlob((blob) => {
        if (blob.size <= 50 * 1024) {
          const compressedFile = new File([blob], `compressed_${Date.now()}.jpg`, {
            type: fileType,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        } else if (quality > 0.1) {
          this.compressImage(canvas, fileType, quality - 0.1, resolve, reject);
        } else {
          const compressedFile = new File([blob], `compressed_${Date.now()}.jpg`, {
            type: fileType,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        }
      }, fileType, quality);
    },

    formatTime(hours) {
      console.log(hours)
      // 将小时数格式化为天时分秒的字符串显示
      const remainingHours = Math.floor(hours);
      console.log(remainingHours)
      const minutes = Math.floor((hours * 60) % 60);
      return `${remainingHours}小时${minutes}分钟`;
    },
    getTag(reconum){
      const recordTag = this.tableData.find(v => v.reconum === reconum).recordTag;
      console.log(recordTag)
      if(recordTag === 'good'){
        return reconum+'👍'
      }
      if(recordTag === 'bad'){
        return reconum+'😟'
      }
      return reconum
    },
    getReconum(recordId) {
      const record = this.tableData.find(v => v.id === recordId);
      if (!record) return '';
      return record ? record.reconum : '';
    },
    getLineName2(lineId) {
      const line = this.lines.find(v => v.id === lineId);
      if (!line) return '';
      return line ? line.name : '';
    },
    getCompName(compnum) {
      const component = this.components.find(v => v.compnum === compnum);
      if (!component) return '';
      return component ? component.name : '';
    },
    getRowStyle(row) {
      if(row.state === '工单关闭'){
        return { backgroundColor: 'lightgreen' }
      }
      if(row.state === '审批不通过'){
        return { backgroundColor: 'lightpink' }
      }
      if(row.state === '作业结束'){
        return { backgroundColor: 'lightblue' }
      }
    },
    handleSizeChange1(row,pageSize) {
      console.log(pageSize1)
      this.pageSize1 = pageSize
      this.viewChart(row)
    },
    handleCurrentChange1(row,pageNum) {
      console.log(pageNum1)
      this.pageNum1 = pageNum
      this.viewChart(row)
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/failureRecord/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.reconum = ""
      this.startDate = ""
      this.endDate = ""
      this.state = []
      this.type = []
      this.lineId = []
      this.important = false
      this.goodTag = false
      this.badTag = false
      this.equipmentName = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      // 获取筛选条件的值
      const params = new URLSearchParams();
      if (this.reconum) {
        params.append('reconum', this.reconum);
      }
      if (this.type) {
        params.append('type', this.type);
      }
      if (this.state) {
        params.append('state', this.state);
      }
      if (this.lineId) {
        params.append('lineId', this.lineId);
      }
      if (this.equipmentName) {
        params.append('equipmentName', this.equipmentName);
      }
      if (this.startDate) {
        params.append('startDate', this.startDate ? `${this.startDate.getFullYear()}-${(this.startDate.getMonth() + 1).toString().padStart(2, '0')}-${this.startDate.getDate().toString().padStart(2, '0')}` : '');
      }
      if (this.endDate) {
        params.append('endDate', this.endDate ? `${this.endDate.getFullYear()}-${(this.endDate.getMonth() + 1).toString().padStart(2, '0')}-${this.endDate.getDate().toString().padStart(2, '0')}` : '');
      }

      // 打开新窗口进行导出
      window.open(this.baseURLdata + "/failureRecord/export?" + params.toString());
    },

    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
